.head {
    width: 100%;
    text-align: center;
}


@media (max-width: 600px) {
    .elm {
        font-size: 5rem;
    }
}

@media (min-width: 600px) {
    .elm {
        font-size: 8rem;
    }
}


.row-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.elm {
    border: black solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1;
}

@media (pointer: fine) {
    .elm:hover {
        box-shadow: 3px 3px 15px gray;
    }
}

.header {
    padding-left: 25px;
}